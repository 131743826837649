import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { paymentTokenService, queryClient } from 'services';
import { InputNumber } from 'components';

type PopupProps = PopupController & {
  item?: PaymentTokenType;
};

const PaymentTokenPopup = ({ item, onClose }: PopupProps) => {
  const isCreate = !item;

  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const { mutate: createPaymentToken, isLoading } = useMutation(
    isCreate ? paymentTokenService.createPaymentToken : paymentTokenService.updatePaymentToken,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('paymentTokenService.fetchPaymentTokens');
        onClose();
      },
    },
  );

  const handleSubmitEvent = () => {
    handleSubmit((values) => {
      const { enabled } = values;
      createPaymentToken({
        ...(values as PaymentTokenUpdateOrCreateType),
        enabled: enabled === 'true' ? true : false,
      });
    })();
  };

  useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>{isCreate ? 'CREATE' : 'UPDATE'} PAYMENT TOKEN</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} multiline fullWidth label='Name' error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='symbol'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth label='Symbol' error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='decimals'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Decimals'
                  error={invalid}
                  helperText={error?.message}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: {
                      decimalScale: 0,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='enabled'
              defaultValue='true'
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} select fullWidth label='Enabled'>
                  <MenuItem value='true'>True</MenuItem>
                  <MenuItem value='false'>False</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='contractAddress'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth label='Contract Address' error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleSubmitEvent}>
          {isCreate ? 'Create' : 'Update'} Payment Token
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PaymentTokenPopup;
