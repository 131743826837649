import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  MenuItem,
  Dialog,
} from '@mui/material';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { paymentTokenService } from 'services';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { DeleteConfirmPopup, PaymentTokenPopup } from 'views/PaymentToken';

const TOKEN_FILTERS = [
  { label: 'Name', code: 'name' },
  { label: 'Symbol', code: 'symbol' },
];

const PaymentTokenList = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching } = useQuery(
    ['paymentTokenService.fetchPaymentTokens', dataSearch],
    () => paymentTokenService.fetchPaymentTokens(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages: totalPage } = data ?? {};

  const { control, watch } = useForm();

  useEffect(() => {
    const subscription = watch(({ orderBy }, { name, type }) => {
      if (name === 'orderBy') {
        if (orderBy) {
          onSearchChange({
            orderBy: TOKEN_FILTERS.find((item) => item.code === orderBy)?.code ?? TOKEN_FILTERS[0].code,
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onSearchChange]);

  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [itemChoice, setItemChoice] = useState<PaymentTokenType>();

  return (
    <>
      <div className='flex items-center justify-between'>
        <Controller
          name='orderBy'
          defaultValue={TOKEN_FILTERS[0].code}
          control={control}
          render={({ field }) => (
            <TextField select {...field}>
              {TOKEN_FILTERS.map((item, index) => (
                <MenuItem key={index} value={item.code}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Button
          variant='contained'
          onClick={() => {
            setItemChoice(undefined);
            setOpenCreatePopup(true);
          }}
        >
          Create Payment Token
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell>Decimals</TableCell>
                <TableCell>Contract Address</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell align='center'>{item.name}</TableCell>
                  <TableCell align='center'>{item.symbol}</TableCell>
                  <TableCell align='center'>{item.decimals}</TableCell>
                  <TableCell align='center'>{item.contractAddress}</TableCell>
                  <TableCell align='center'>{item.enabled.toString()}</TableCell>
                  <TableCell align='center'>
                    {item.createdAt && DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm')}
                  </TableCell>
                  <TableCell align='center'>
                    {item.updatedAt && DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    <div className='flex items-center space-x-2 justify-center'>
                      <Button
                        variant='contained'
                        size='medium'
                        color='warning'
                        onClick={() => {
                          setOpenCreatePopup(true);
                          setItemChoice(item);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        variant='contained'
                        color='error'
                        size='medium'
                        onClick={() => {
                          setOpenDeletePopup(true);
                          setItemChoice(item);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{total ?? 0} Payment Tokens</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openDeletePopup} fullWidth maxWidth='xs'>
        <DeleteConfirmPopup id={itemChoice?.id!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>

      <Dialog open={openCreatePopup} fullWidth maxWidth='sm'>
        <PaymentTokenPopup item={itemChoice} onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
    </>
  );
};

export default PaymentTokenList;
