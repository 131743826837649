import { client } from './axios';

const fetchCalendarEvents = (params?: CalendarEventParam): Promise<EventTypeResponse[]> => {
  return client.get(`/dashboard-apis/api/calendarevent`, { params });
};

const createCalendarEvent = (body: CalendarEventCreateOrUpdateType) => {
  return client.post(`/dashboard-apis/api/calendarevent`, body);
};

const updateCalendarEvent = ({ eventId, ...body }: CalendarEventCreateOrUpdateType) => {
  return client.put(`/dashboard-apis/api/calendarevent/${eventId}`, body);
};

const deleteCalendarEvent = ({ id }: { id: string }) => client.delete(`/dashboard-apis/api/calendarevent/${id}`);

const fetchCategoriesEvent = (params?: CalendarCategoryParam): Promise<CategoryEventType[]> =>
  client.get(`/dashboard-apis/api/calendarcategory`, { params });

const createCalendarCategory = (body: CalendarCategoryCreateOrUpdateType) =>
  client.post(`/dashboard-apis/api/calendarcategory`, body);

const updateCalendarCategory = ({ categoryId, ...body }: CalendarCategoryCreateOrUpdateType) =>
  client.put(`/dashboard-apis/api/calendarcategory/${categoryId}`, body);

const deleteCalendarCategory = ({ id }: { id: string }) => client.delete(`/dashboard-apis/api/calendarcategory/${id}`);

export default {
  fetchCalendarEvents,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,

  fetchCategoriesEvent,
  createCalendarCategory,
  updateCalendarCategory,
  deleteCalendarCategory,
};
