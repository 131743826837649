import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  TableSortLabel,
} from '@mui/material';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { dashboardService } from 'services';
import { shorten } from 'utils/common';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { FileDownloadOutlined } from '@mui/icons-material';
import { DetailPopup, ExportPopup } from '.';

const RefList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [desc, setDesc] = useState(true);
  const [dataSearch, onSearchChange] = useSearch({ search: '', desc });
  const [paramsQueries, setParamsQueries] = useState({ search: '' });

  const [openExportPopup, setOpenExportPopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const handleRequestSort = () => {
    setDesc(!desc);
    onSearchChange({ desc: !desc });
  };

  const { data, isFetching } = useQuery(
    ['dashboardService.fetchRefs', dataSearch],
    () => dashboardService.fetchRefs(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages: totalPage } = data ?? {};

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <CommonSearch setParamsQueries={setParamsQueries} onChange={onSearchChange} />
        <Button variant='contained' startIcon={<FileDownloadOutlined />} onClick={() => setOpenExportPopup(true)}>
          Export
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Refcode</TableCell>
                <TableCell align='right' padding='normal' sortDirection={desc ? 'desc' : 'asc'}>
                  <TableSortLabel active={true} direction={desc ? 'desc' : 'asc'} onClick={handleRequestSort}>
                    Total Refs
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  key={item.refCode}
                  onClick={() => {
                    setOpenDetailPopup(true);
                    setSelectedUserId(item.id);
                  }}
                >
                  <TableCell className='text-center whitespace-nowrap'>{item.username}</TableCell>
                  <TableCell>
                    <div className='flex items-center justify-center space-x-2'>
                      <div>{shorten(item.address)}</div>
                      {item.address && (
                        <IconButton onClick={() => copy(item.address)} title='Copy'>
                          <ContentCopyOutlined />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align='center'>{item.email}</TableCell>
                  <TableCell align='center'>{item.refCode}</TableCell>
                  <TableCell align='center'>{item.totalRef}</TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{total ?? 0} Users</caption>
          </Table>
        </Spinner>
      </TableContainer>
      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openExportPopup} fullWidth maxWidth='sm'>
        <ExportPopup total={total!} paramsQueries={paramsQueries} onClose={() => setOpenExportPopup(false)} />
      </Dialog>
      <Dialog open={openDetailPopup} fullWidth maxWidth='lg'>
        <DetailPopup userId={selectedUserId} onClose={() => setOpenDetailPopup(false)} />
      </Dialog>
    </>
  );
};

export default RefList;
