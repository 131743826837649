import { CardAirdropEvent, AirdropEventPopup } from 'views/Airdrop';
import { Container, Button, Dialog, Pagination } from '@mui/material';
import { airdropService } from 'services';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { useSearch } from 'hooks';
import { CommonSearch } from 'components';

const AirdropEventList = () => {
  const [dataSearch, onSearchChange] = useSearch({ search: '' });
  const { data } = useQuery(
    ['airdropService.fetchAirdropEvents', { orderBy: 'fromDate', desc: true, ...dataSearch }],
    () => airdropService.fetchAirdropEvents({ orderBy: 'fromDate', desc: true, ...dataSearch }),
  );

  const [openCreatePopup, setOpenCreatePopup] = useState(false);

  const { items = [], currentPage, pages: totalPage } = data ?? {};

  return (
    <>
      <Container maxWidth='lg' className='flex flex-wrap sm:flex-nowrap xl:justify-between items-center px-0'>
        <CommonSearch onChange={onSearchChange} />
        <Button variant='contained' className='mt-2 sm:mt-0 w-40' onClick={() => setOpenCreatePopup(true)}>
          Create Event
        </Button>
      </Container>
      <Container maxWidth='lg' className='flex flex-col mt-4 gap-10 px-0'>
        {items ? (
          items.length > 0 ? (
            items.map((item) => <CardAirdropEvent key={item.id} item={item} />)
          ) : (
            <div className='text-center'>
              <img src={require('assets/icons/Metafarm.png').default} className='mx-auto' />
              <div className='font-black text-2xl text-info-dark mb-3'>AIRDROP IS COMING!</div>
              <div className='font-semibold text-base'>
                {"Don't miss out! We are preparing for better airdrop events for citizens."}
                <br />
                {'Follow our news and keep an eye out for your super cool NFTs!'}
              </div>
            </div>
          )
        ) : null}

        <div className='flex justify-center'>
          <Pagination
            page={currentPage ?? 1}
            count={totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </Container>

      <Dialog open={openCreatePopup} fullWidth maxWidth='sm'>
        <AirdropEventPopup isSubEvent={false} onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
    </>
  );
};

export default AirdropEventList;
