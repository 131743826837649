import {
  CircularProgress,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
} from '@mui/material';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { dashboardService } from 'services';
import { shorten } from 'utils/common';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { FileDownloadOutlined } from '@mui/icons-material';
import { ExportPopup } from '.';

const EastfogAccountList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch();
  const [selectedId, setSelectedId] = useState('');
  const [openExportPopup, setOpenExportPopup] = useState(false);

  const [paramsQueries, setParamsQueries] = useState({ search: '' });

  const { data, isFetching, refetch } = useQuery(
    ['dashboardService.fetchAccounts', dataSearch],
    () => dashboardService.fetchAccounts(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], currentPage, pages: totalPage } = data ?? {};

  const { data: accountStatistics } = useQuery(
    ['dashboardService.getAccount', paramsQueries],
    () => dashboardService.getAccountStatistics(paramsQueries),
    {
      keepPreviousData: true,
    },
  );

  const { mutate: setWhiteList, isLoading: loadingSetWhitelist } = useMutation(dashboardService.setWhiteList, {
    onSuccess: () => {
      refetch();
    },
  });

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <CommonSearch setParamsQueries={setParamsQueries} onChange={onSearchChange} />
        <Button variant='contained' startIcon={<FileDownloadOutlined />} onClick={() => setOpenExportPopup(true)}>
          Export
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Email Confirmed</TableCell>
                <TableCell>Whitelist</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='text-center whitespace-nowrap'>{item.username}</TableCell>
                  <TableCell>
                    <div className='flex items-center justify-center space-x-2'>
                      <div>{shorten(item.address)}</div>
                      {item.address && (
                        <IconButton onClick={() => copy(item.address)} title='Copy'>
                          <ContentCopyOutlined />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className='text-center'>{item.email}</TableCell>
                  <TableCell className='text-center capitalize'>{item.emailConfirmed.toString()}</TableCell>
                  <TableCell className='text-center'>
                    {loadingSetWhitelist && item.id === selectedId ? (
                      <div className='pt-2'>
                        <CircularProgress size={24} />
                      </div>
                    ) : (
                      <Switch
                        checked={item.whitelist}
                        onClick={() => {
                          setSelectedId(item.id);
                          setWhiteList({ address: item.address, email: item.email, isWhitelisted: !item.whitelist });
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t '>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-3'>
                  <div>{accountStatistics?.numAccounts ?? 0} Accounts</div>
                  <div>{accountStatistics?.numEmailConfirmed ?? 0} Emails Confirmed</div>
                  <div>{accountStatistics?.numWhitelist ?? 0} Whitelists</div>
                </div>
              </div>
            </caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openExportPopup} fullWidth maxWidth='sm'>
        <ExportPopup paramsQueries={paramsQueries} onClose={() => setOpenExportPopup(false)} />
      </Dialog>
    </>
  );
};

export default EastfogAccountList;
