import { CardMedia, Grid, Paper, IconButton, Menu, MenuItem, Dialog } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CountdownTimer } from 'components';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import { useAnchor } from 'hooks';
import { useState } from 'react';
import { DeleteConfirmPopup, AirdropEventPopup } from 'views/Airdrop';
import { getTimeFromNumber } from 'utils/common';

const CardAirdropEvent = ({ item }: { item: AirdropEventType }) => {
  const [anchorEventSettings, openEventSettings, onOpenEventSettings, onCloseEventSettings] = useAnchor();

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [itemChoice, setItemChoice] = useState<AirdropEventType>();

  return (
    <Paper className='md:p-10 p-4 rounded-3xl shadow-md relative'>
      <div className='absolute top-0 right-0 p-4 z-10'>
        <IconButton onClick={onOpenEventSettings}>
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={anchorEventSettings}
          open={openEventSettings}
          onClose={onOpenEventSettings}
          onClick={onCloseEventSettings}
        >
          <MenuItem
            onClick={() => {
              setItemChoice(item);
              setOpenEditPopup(true);
            }}
          >
            <Edit fontSize='small' />
            <span className='ml-2'>Edit</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setItemChoice(item);
              setOpenDeletePopup(true);
            }}
          >
            <Delete fontSize='small' />
            <span className='ml-2'>Delete</span>
          </MenuItem>
        </Menu>
      </div>
      <Grid container spacing={5} className='mt-1 md:mt-0'>
        <Grid item md={5.5} xs={12}>
          {item.itemVideo ? (
            <CardMedia
              component='video'
              image={item.itemVideo}
              className='rounded-2xl mx-auto'
              playsInline
              autoPlay
              loop
              muted
              controls
              controlsList='nodownload'
            />
          ) : (
            <CardMedia
              image={item.itemImage}
              className='bg-contain h-full'
              sx={{
                minHeight: 240,
              }}
            />
          )}
        </Grid>
        <Grid item md={6.5} xs={12} className='flex flex-col items-start gap-3'>
          <div className='bg-blue-400 text-white font-black rounded-lg px-3 py-1'>{item.name}</div>
          {item.fromDate && item.toDate && (
            <CountdownTimer
              endTime={
                DateTime.now() > DateTime.fromISO(getTimeFromNumber(item.toDate))
                  ? getTimeFromNumber(item.fromDate)
                  : getTimeFromNumber(item.toDate)
              }
            />
          )}
          <div className='font-semibold text-base whitespace-pre-line'>{item.description}</div>
          <div className='font-bold text-primary-main'>{item.condition}</div>
          <div className='flex-1 flex flex-row items-end md:gap-6 gap-3'>
            <a href={item.joinLink} rel='noreferrer' target='_blank'>
              <LoadingButton color='secondary' variant='contained'>
                HOW TO JOIN
              </LoadingButton>
            </a>
            <Link to={privateRoute.airdropEventDetail.url?.(item)!}>
              <LoadingButton color='primary' variant='outlined'>
                EVENT DETAIL
              </LoadingButton>
            </Link>
          </div>
        </Grid>
      </Grid>

      <Dialog open={openDeletePopup} fullWidth maxWidth='xs'>
        <DeleteConfirmPopup id={itemChoice?.id!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>

      <Dialog open={openEditPopup} fullWidth maxWidth='sm'>
        <AirdropEventPopup isSubEvent={false} item={itemChoice} onClose={() => setOpenEditPopup(false)} />
      </Dialog>
    </Paper>
  );
};

export default CardAirdropEvent;
