import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from 'react-query';
import { saleService, queryClient } from 'services';

type PopupProps = PopupController & {
  sale: SaleType;
};

const DeleteSalePopup = ({ sale, onClose }: PopupProps) => {
  const { tokenId, saltNonce } = sale;
  const { mutate: deleteSaleItem, isLoading } = useMutation(saleService.deleteSaleItem, {
    onSuccess: () => {
      queryClient.invalidateQueries('saleService.fetchSales');
      onClose();
    },
  });

  const handleDelete = () => {
    deleteSaleItem({ tokenId, saltNonce });
  };

  return (
    <>
      <DialogTitle>CONFIRMATION</DialogTitle>

      <DialogContent>
        Are you want to <span className='font-bold text-red-700'>DELETE</span> this sale ?
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleDelete}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DeleteSalePopup;
