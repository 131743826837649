import { Button, Dialog } from '@mui/material';
import { useState } from 'react';
import merge from 'utils/merge';
import { CalendarEventPopup } from 'views/CalendarEvents';
import styles from './Events.module.scss';

const TopLogo = () => {
  const [openCreatePopup, setOpenCreatePopup] = useState(false);

  return (
    <>
      <div className='flex items-center justify-between md:pb-8 pb-4'>
        <div className={merge(styles.header, 'md:text-5xl text-2xl')}>Events</div>
        <Button variant='contained' onClick={() => setOpenCreatePopup(true)}>
          Create Calendar Event
        </Button>
      </div>

      <Dialog open={openCreatePopup} fullWidth maxWidth='md'>
        <CalendarEventPopup onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
    </>
  );
};

export default TopLogo;
