import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { useMutation } from 'react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { systemSelector } from 'reducers/system';
import { dashboardService, queryClient } from 'services';

const SystemConfig = () => {
  const { control, setValue, handleSubmit } = useForm({ mode: 'onChange' });

  const system = useSelector(systemSelector);

  useEffect(() => {
    if (system) {
      Object.entries(system).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [system, setValue]);

  const { mutate: updateSystem, isLoading } = useMutation(dashboardService.updateSystem, {
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardService.fetchSystem']);
    },
  });

  const handleUpdateSystem = () => {
    handleSubmit((values) => {
      updateSystem({
        ...values,
      } as SystemUpdateType);
    })();
  };

  return (
    <>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <Controller
              name='chainId'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  multiline
                  fullWidth
                  label='Chain Id'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Controller
              name='chainName'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth label='Chain Name' error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Controller
              name='metaverseContractAddress'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Metaverse Contract Address'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Controller
              name='boxPaymentContractAddress'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Box Payment Contract Address'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Controller
              name='boxContractAddress'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Box Contract Address'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Controller
              name='marketplaceAddress'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Marketplace Address'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Controller
              name='nftContractAddress'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='NFT Contract Address'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3} className='flex items-center'>
            <Controller
              name='userMintEnable'
              defaultValue={false}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel control={<Checkbox onChange={onChange} checked={value} />} label='User Mint' />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div className='flex justify-end mt-4'>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleUpdateSystem}>
          Update System
        </LoadingButton>
      </div>
    </>
  );
};

export default SystemConfig;
