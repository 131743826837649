import { ContentCopyOutlined } from '@mui/icons-material';
import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { dashboardService } from 'services';
import { shorten } from 'utils/common';

const ItemList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch({ search: '' });

  const { data, isFetching } = useQuery(
    ['dashboardService.fetchItems', dataSearch],
    () => dashboardService.fetchItems(dataSearch),
    { keepPreviousData: true },
  );

  const { items = [], total, currentPage, pages: totalPage } = data ?? {};

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <>
      <CommonSearch onChange={onSearchChange} />

      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Token Id</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>External</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='whitespace-nowrap'>{item.name}</TableCell>
                  <TableCell>{item.tokenId}</TableCell>
                  <TableCell>
                    <div className='flex items-center space-x-2'>
                      <div>{shorten(item.creator.address)}</div>
                      {item.creator.address && (
                        <IconButton onClick={() => copy(item.creator.address)} title='Copy'>
                          <ContentCopyOutlined />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className='flex items-center space-x-2'>
                      <div>{shorten(item.owner.address)}</div>
                      {item.owner.address && (
                        <IconButton onClick={() => copy(item.owner.address)} title='Copy'>
                          <ContentCopyOutlined />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.external}</TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{total ?? 0} Items</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </>
  );
};

export default ItemList;
