import { client } from './axios';

const mintItem = (body: ItemMintType) => client.post(`/dashboard-apis/api/items/mint`, body);
const updateItem = ({ tokenId, ...body }: ItemUpdateType) => client.put(`/dashboard-apis/api/items/${tokenId}`, body);

const syncItemByAddress = ({ address }: { address: string }): Promise<string[]> =>
  client.post(`/dashboard-apis/api/items/data-sync`, { address });

const syncItemByTokenID = ({ tokenId }: { tokenId: string }): Promise<string[]> =>
  client.post(`/dashboard-apis/api/items/update-owner`, { tokenId });

export default {
  mintItem,
  updateItem,
  syncItemByAddress,
  syncItemByTokenID,
};
