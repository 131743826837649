import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from 'react-query';
import { paymentTokenService, queryClient } from 'services';

type PopupProps = PopupController & {
  id: string;
};

const DeleteConfirmPopup = ({ id, onClose }: PopupProps) => {
  const { mutate: deletePaymentToken, isLoading } = useMutation(paymentTokenService.deletePaymentToken, {
    onSuccess: () => {
      queryClient.invalidateQueries('paymentTokenService.fetchPaymentTokens');
      onClose();
    },
  });

  const handleDelete = () => {
    deletePaymentToken({ id });
  };

  return (
    <>
      <DialogTitle>CONFIRMATION</DialogTitle>

      <DialogContent>
        Are you want to <span className='font-bold text-red-700'>DELETE</span> this payment token ?
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleDelete}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DeleteConfirmPopup;
