import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@mui/material';
import { Spinner, TableRowEmpty } from 'components';
import { useQuery } from 'react-query';
import { dashboardService } from 'services';

const ServerStatistic = () => {
  const { data, isFetching } = useQuery(
    ['dashboardService.fetchServerStatistics'],
    () => dashboardService.fetchServerStatistics(),
    { keepPreviousData: true },
  );
  const { servers = [], count } = data ?? {};

  const sumConnections = servers.reduce((acc, cur) => acc + cur.connections, 0);

  return (
    <>
      <Chip label={`${sumConnections} Users`} />
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Connections</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {servers.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell className='text-center'>{item.address}</TableCell>
                  <TableCell className='text-center'>{item.connections}</TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && servers.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{count ?? 0} Servers</caption>
          </Table>
        </Spinner>
      </TableContainer>
    </>
  );
};

export default ServerStatistic;
