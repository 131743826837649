import { Switch, Typography, Tooltip, Dialog, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { systemSelector } from 'reducers/system';
import { Construction } from '@mui/icons-material';
import { useState } from 'react';
import { MaintenanceConfirmPopup } from 'views/Maintenance';

const Maintenance = () => {
  const { isMaintainceEnabled } = useSelector(systemSelector);
  const [open, setOpen] = useState(false);

  return (
    <Paper className='px-4 py-2'>
      <div className='flex items-center justify-between'>
        <Tooltip title='Maintenance'>
          <Construction />
        </Tooltip>
        <div className='flex items-center'>
          <Switch checked={isMaintainceEnabled === true} onClick={() => setOpen(true)} />
          <Typography variant='h5'>{isMaintainceEnabled ? 'On' : 'Off'}</Typography>
        </div>
      </div>

      <Dialog fullWidth maxWidth='xs' open={open} onClose={() => setOpen(false)}>
        <MaintenanceConfirmPopup onClose={() => setOpen(false)} />
      </Dialog>
    </Paper>
  );
};

export default Maintenance;
