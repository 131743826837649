import { Grid, TextField, Autocomplete, Chip, MenuItem } from '@mui/material';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { InputNumber } from 'components';
import { itemService } from 'services';

const ERC_TYPE = [
  { label: 'ERC-721', code: '721' },
  { label: 'ERC-1155', code: '1155' },
];

const ItemMint = () => {
  const { control, handleSubmit, reset, setValue, watch } = useForm({ mode: 'onChange' });

  const { type } = watch();

  const { mutate: mintItem, isLoading } = useMutation(itemService.mintItem, {
    onSuccess: () => {
      reset();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mintItem(values as ItemMintType);
    })();
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Controller
            name='type'
            defaultValue={ERC_TYPE[0].code}
            control={control}
            render={({ field }) => (
              <TextField select {...field} label='Type'>
                {ERC_TYPE.map((item, index) => (
                  <MenuItem key={index} value={item.code}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='tokenIds'
            defaultValue={[]}
            control={control}
            rules={{ required: 'Token IDs is required' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <Autocomplete
                multiple
                options={[]}
                freeSolo
                fullWidth
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    variant='outlined'
                    label='Token IDs'
                    InputLabelProps={{ shrink: true }}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
                onChange={(event, value) => {
                  setValue('tokenIds', value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='itemId'
            defaultValue=''
            control={control}
            rules={{ required: 'Game Item ID is required' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label='Game Item ID'
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={3}>
          <Controller
            name='amount'
            defaultValue='1'
            control={control}
            rules={{ required: 'Amount is required' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label='Amount'
                InputProps={{
                  inputComponent: InputNumber as any,
                  inputProps: {
                    decimalScale: 0,
                  },
                }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        {type === '1155' && (
          <Grid item sm={3}>
            <Controller
              name='owner'
              defaultValue=''
              control={control}
              rules={{ required: 'Owner Address is required' }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Owner Address'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        )}

        <Grid item sm={12}>
          <LoadingButton
            variant='outlined'
            size='large'
            className='w-28'
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Mint
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ItemMint;
