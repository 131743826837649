import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  OutlinedInput,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { dashboardService } from 'services';

const EXPORT_FIELDS = ['username', 'address', 'email'];

type ExportPopupProps = PopupController & {
  paramsQueries: Object;
  total: number;
};

const ExportPopup = ({ total, paramsQueries, onClose }: ExportPopupProps) => {
  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });

  const { fields = EXPORT_FIELDS } = watch();

  const { mutate, isLoading } = useMutation(dashboardService.exportTopRef, {
    onSuccess: (res) => {
      const blob = new Blob([res], { type: 'text/csv;charset=UTF-8' });
      const blobURL = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'refs-statistics';
      anchor.href = blobURL;
      anchor.click();
      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 100);
    },
  });

  const handleExport = () => {
    handleSubmit((values) => {
      const { topNum } = values;
      mutate({
        ...values,
        topNum: Number(topNum),
        ...paramsQueries,
      } as RefExportType);
    })();
  };

  return (
    <>
      <DialogTitle>EXPORT</DialogTitle>

      <DialogContent>
        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12}>
            <Controller
              name='fields'
              defaultValue={fields}
              control={control}
              render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                <FormControl fullWidth>
                  <InputLabel>Export Fields</InputLabel>
                  <Select
                    multiple
                    value={value}
                    onChange={onChange}
                    label='Export Fields'
                    input={<OutlinedInput label='Export Fields' />}
                    renderValue={(selected: any) => selected.map((x: string) => x).join(', ')}
                  >
                    {EXPORT_FIELDS.map((field) => (
                      <MenuItem key={field} value={field}>
                        <Checkbox checked={fields.findIndex((item: string) => item === field) >= 0} />
                        <ListItemText primary={field} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='topNum'
              defaultValue={10}
              control={control}
              rules={{ min: 0, max: total }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  type='number'
                  label='Top Number'
                  error={invalid}
                  helperText={error?.message}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: total,
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleExport}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ExportPopup;
