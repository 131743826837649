import { ArrowBack } from '@mui/icons-material';
import { Button, Container, Dialog } from '@mui/material';
import { CountdownTimer } from 'components';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { airdropService } from 'services';
import { CardAirdropItem, AirdropEventPopup } from 'views/Airdrop';
import { getTimeFromNumber } from 'utils/common';
import { useState } from 'react';

const AirdropEventDetail = () => {
  const params = useParams();

  const { data: item, isSuccess } = useQuery(['airdropService.getAirdropEventById', { id: params.id }], () =>
    airdropService.getAirdropEventById({ id: params.id as string }),
  ) as { data: AirdropEventType; isSuccess: boolean };

  const [openCreatePopup, setOpenCreatePopup] = useState(false);

  if (!isSuccess) return <></>;
  return (
    <Container className='relative'>
      <div className='flex items-center justify-between'>
        <Link to={privateRoute.airdropEvent.path}>
          <Button variant='text' color='primary' startIcon={<ArrowBack />}>
            Back
          </Button>
        </Link>
        <Button variant='contained' onClick={() => setOpenCreatePopup(true)}>
          Create Sub Event
        </Button>
      </div>

      <Container maxWidth='sm' className='flex flex-col items-center gap-3 text-center mb-10'>
        <div className='font-black text-2xl text-primary-main'>{item.name}</div>
        {item.toDate && <CountdownTimer endTime={getTimeFromNumber(item.toDate)} />}
        <div className='font-semibold text-base whitespace-pre-line'>{item.description}</div>
      </Container>

      <Container maxWidth='lg' className='flex flex-col gap-10 px-0'>
        {item.events?.map((event) => (
          <CardAirdropItem key={event.id} item={item} event={event} />
        ))}
      </Container>

      <Dialog open={openCreatePopup} fullWidth maxWidth='sm'>
        <AirdropEventPopup isSubEvent={true} onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
    </Container>
  );
};

export default AirdropEventDetail;
