import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  Button,
} from '@mui/material';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { saleService } from 'services';
import { useState } from 'react';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { shorten } from 'utils/common';
import { DeleteSalePopup } from './popups';

const SaleList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch({ search: '' });
  const [selectedSale, setSelectedSale] = useState<SaleType>();

  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const { data, isFetching } = useQuery(
    ['saleService.fetchSales', dataSearch],
    () => saleService.fetchSales(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages: totalPage } = data ?? {};

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <>
      <CommonSearch onChange={onSearchChange} />

      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Token Id</TableCell>
                <TableCell>Owner Address</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='whitespace-nowrap'>{item.name}</TableCell>
                  <TableCell>
                    {item.price.toFixed(2)} {item.paymentToken?.name ?? 'MATIC'}
                  </TableCell>
                  <TableCell>{item.tokenId}</TableCell>
                  <TableCell>
                    <div className='flex items-center justify-center space-x-2'>
                      <div>{shorten(item.ownerAddress)}</div>
                      {item.ownerAddress && (
                        <IconButton onClick={() => copy(item.ownerAddress)} title='Copy'>
                          <ContentCopyOutlined />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className='whitespace-nowrap 2xl:whitespace-normal'>{item.description}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      color='error'
                      size='medium'
                      onClick={() => {
                        setOpenDeletePopup(true);
                        setSelectedSale(item);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{total ?? 0} Sales</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openDeletePopup} fullWidth maxWidth='sm'>
        <DeleteSalePopup sale={selectedSale!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>
    </>
  );
};

export default SaleList;
