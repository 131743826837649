import { TopLogo, Calendar } from 'views/CalendarEvents';

const CalendarEvents = () => {
  return (
    <div>
      <TopLogo />
      <Calendar />
    </div>
  );
};

export default CalendarEvents;
