import { client, clientDownload } from './axios';

const fetchSystem = (): Promise<SystemType> => client.get(`/dashboard-apis/api/dashboard/system-config`);
const updateSystem = ({ id, ...body }: SystemUpdateType) =>
  client.put(`/dashboard-apis/api/dashboard/system-config/${id}`, body);

const fetchDashboard = (params?: DashboardParamsType): Promise<DashboardType> =>
  client.get(`/dashboard-apis/api/dashboard`, { params });
const fetchServerStatistics = (): Promise<DashboardServerType> =>
  client.get(`/dashboard-apis/api/dashboard/server-statistics`);
const fetchRefs = (params: RefParamsType): Promise<RefPaginateType> =>
  client.get(`/dashboard-apis/api/dashboard/top-ref`, { params });
const getRefsUser = (params: RefUserDetailParamsType): Promise<RefUserDetailPaginateType> =>
  client.get(`/dashboard-apis/api/users/ref-details`, { params });

const fetchItems = (params: ItemParamsType): Promise<ItemPaginateType> =>
  client.get(`/dashboard-apis/api/items`, { params });
const fetchUsers = (params: UserParamsType): Promise<UserPaginateType> =>
  client.get(`/dashboard-apis/api/users`, { params });
const fetchAccounts = (params: AccountParamsType): Promise<AccountPaginateType> =>
  client.get(`/dashboard-apis/api/eastfog-items/get-accounts`, { params });

const setWhiteList = (body: AccountSetWhiteList) =>
  client.post(`/dashboard-apis/api/eastfog-items/set-whitelist`, body);

const setMinter = (body: AccountSetMinter) => client.post(`/dashboard-apis/api/users/set-minter`, body);

const fetchItemsEastFog = (params: ItemEastFogParamsType): Promise<ItemEastFogPaginateType> =>
  client.get(`dashboard-apis/api/eastfog-items`, { params });

const fetchEastFogItemStatistic = (params: ItemStatisticParamsType): Promise<ItemStatisticPaginateType> =>
  client.get(`dashboard-apis/api/eastfog-items/get-nftitem-count`, { params });

const fetchMinigameLeaderBoard = (params: GamePlayerTopParams): Promise<GamePlayerType[]> =>
  client.get(`dashboard-apis/api/game/minigame_leaderboard`, { params });

const fetchBattleRoyaleLeaderBoard = (params: GamePlayerTopParams): Promise<GamePlayerType[]> =>
  client.get(`dashboard-apis/api/game/battleroyale_leaderboard`, { params });

const getAccountStatistics = (params: EastfogAccountStatisticParamsType): Promise<EastfogAccountStatisticType> =>
  client.get(`/dashboard-apis/api/dashboard/account-statistics`, { params });

const exportAccount = (body: EastfogAccountExportType): Promise<any> =>
  clientDownload.post(`/dashboard-apis/api/dashboard/export-account`, body);

const exportTopRef = (body: RefExportType): Promise<any> =>
  clientDownload.post(`/dashboard-apis/api/dashboard/export-top-ref`, body);

export default {
  fetchSystem,
  updateSystem,

  fetchDashboard,
  fetchServerStatistics,

  fetchUsers,
  fetchItems,
  fetchRefs,
  getRefsUser,
  fetchAccounts,

  setWhiteList,
  setMinter,
  fetchItemsEastFog,
  fetchEastFogItemStatistic,

  fetchMinigameLeaderBoard,
  fetchBattleRoyaleLeaderBoard,

  getAccountStatistics,
  exportAccount,
  exportTopRef,
};
