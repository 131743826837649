import { ImageOutlined } from '@mui/icons-material';
import { DesktopDateTimePicker, LoadingButton } from '@mui/lab';
import {
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  FormHelperText,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { calendarService, fileService, queryClient } from 'services';
import { getBase64 } from 'utils/common';

type PopupProps = PopupController & {
  item?: EventType;
};

const PRIORITY_TYPE = [
  { label: 'Normal', code: 'normal' },
  { label: 'High', code: 'high' },
  { label: 'Very High', code: 'veryHigh' },
];

const CalendarEventPopup = ({ item, onClose }: PopupProps) => {
  const isCreate = !item;

  const { control, handleSubmit, clearErrors, setValue, watch } = useForm({ mode: 'onChange' });

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [image, setImage] = useState<string>();
  const { type = '', startTime } = watch();

  const { data: categories } = useQuery(
    ['calendarService.fetchCategoriesEvent'],
    () => calendarService.fetchCategoriesEvent(),
    {
      keepPreviousData: true,
    },
  );

  const { mutate, isLoading } = useMutation(
    isCreate ? calendarService.createCalendarEvent : calendarService.updateCalendarEvent,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('calendarService.fetchCalendarEvents');
        onClose();
      },
    },
  );

  const handleChangeFile = (event: any) => {
    const [file] = event.target.files;
    if (!file) return;
    getBase64(file, setImage);

    if (file) {
      setLoadingUpload(true);
      const formData = new FormData();
      formData.append('file', file);
      fileService
        .uploadFile(formData)
        .then((data) => {
          setValue('bannerUrl', data);
        })
        .finally(() => setLoadingUpload(false));
      clearErrors('image');
    }
  };

  const handleSubmitEvent = () => {
    handleSubmit((values) => {
      const { startTime, endTime, ...others } = values;

      const startTimeSecs = Math.floor(new Date(startTime).getTime() / 1000);
      const endTimeSecs = Math.floor(new Date(endTime).getTime() / 1000);

      mutate({
        ...others,
        startTime: startTimeSecs,
        endTime: endTimeSecs,
        eventId: item?.id,
      });
    })();
  };

  useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        if (key === 'category') setValue('categoryName', value.name);
        setValue(key, value);
      });
      setImage(item.bannerUrl);
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>{isCreate ? 'CREATE' : 'UPDATE'} CALENDAR EVENT</DialogTitle>

      <DialogContent>
        <Controller
          name='bannerUrl'
          defaultValue=''
          control={control}
          rules={{ required: 'Image or Video is required' }}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-1'>
              <input hidden type='file' id='image' accept='image/*,video/*' onChange={handleChangeFile} />
              <label
                htmlFor='image'
                className='cursor-pointer flex items-center justify-center border-2 border-dashed hover:border-gray-500 rounded'
              >
                <CardMedia
                  component={type.includes('video') ? 'video' : 'div'}
                  image={image}
                  className='flex justify-center items-center bg-contain h-52 w-3/4'
                  style={{
                    ...(invalid ? { borderColor: 'red' } : {}),
                    backgroundColor: 'rgba(250, 251, 252, 1)',
                  }}
                  {...{ controls: true, autoPlay: true, muted: true, loop: true }}
                  controlsList='nodownload'
                >
                  {!image && <ImageOutlined className='w-14 h-14' sx={{ color: '#A5A9BA' }} />}
                </CardMedia>
              </label>
              {!image && error && <FormHelperText className='text-red-700'>{error?.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12} sm={6}>
            <Controller
              name='startTime'
              defaultValue={DateTime.now()}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDateTimePicker
                  value={value}
                  label='Start Time'
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} fullWidth error={invalid} />}
                  inputFormat='dd/MM/yyyy HH:mm'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='endTime'
              defaultValue={DateTime.now()}
              control={control}
              rules={{
                validate: {
                  isValid: (value: DateTime) => value && value.isValid,
                  isAfter: (value) =>
                    DateTime.fromISO(value) > DateTime.fromISO(startTime) || 'To Date should be after the From Date',
                },
              }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDateTimePicker
                  value={value}
                  onChange={onChange}
                  label='End Time'
                  renderInput={(props) => (
                    <TextField {...props} fullWidth error={invalid} helperText={error?.message} />
                  )}
                  inputFormat='dd/MM/yyyy HH:mm'
                />
              )}
            />
          </Grid>
        </Grid>
        <div className='mt-6 flex flex-col gap-6'>
          <Controller
            name='title'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label='Title' error={invalid} helperText={error?.message} />
            )}
          />

          <Controller
            name='detail'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                multiline
                fullWidth
                label='Detail'
                minRows={2}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name='howToWin'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label='How To Win' error={invalid} helperText={error?.message} />
            )}
          />
          <Controller
            name='externalUrl'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label='External Url' error={invalid} helperText={error?.message} />
            )}
          />
          <Controller
            name='prize'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label='Prize' error={invalid} helperText={error?.message} />
            )}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='priority'
                defaultValue={PRIORITY_TYPE[0].code}
                control={control}
                render={({ field }) => (
                  <TextField select fullWidth {...field} label='Priority'>
                    {PRIORITY_TYPE.map((item, index) => (
                      <MenuItem key={index} value={item.code}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='categoryName'
                defaultValue={null}
                control={control}
                rules={{ required: 'Category is required' }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                  <Autocomplete
                    value={value}
                    onChange={(event, item: CategoryEventType) => {
                      onChange({ target: { value: item.name } });
                      setValue('category', item.id);
                    }}
                    options={categories ?? []}
                    disableClearable
                    getOptionLabel={(option: CategoryEventType) => option.name ?? option}
                    isOptionEqualToValue={(option: CategoryEventType, value: any) =>
                      option === value || option.name === value
                    }
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        required
                        label='Category'
                        error={invalid}
                        helperText={error?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} disabled={loadingUpload} onClick={handleSubmitEvent}>
          {isCreate ? 'Create' : 'Update'}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CalendarEventPopup;
