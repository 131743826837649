import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { Maintenance } from 'views/Maintenance';

const StyledListItem = styled(ListItemButton)({
  borderRadius: 8,
  '&.Mui-selected': {
    backgroundColor: 'var(--color-primary-main) !important',
  },
  '&:hover': {
    backgroundColor: 'var(--color-primary-light) !important',
  },
  marginBottom: 2,
});

type SubMenuType = {
  name?: string | JSX.Element;
  path: string;
};

type MenuItemProps = {
  name?: string | JSX.Element;
  path: string;
  items?: SubMenuType[];
};

const MenuItem = ({ name, path, items }: MenuItemProps) => {
  const location = useLocation();
  const [open, setOpen] = useState(location.pathname.startsWith(path));

  return (
    <>
      {items ? (
        <StyledListItem onClick={() => setOpen(!open)}>
          <ListItemText classes={{ primary: 'font-medium' }}>{name}</ListItemText>
          {!open ? <ArrowRight /> : <ArrowDropDown />}
        </StyledListItem>
      ) : (
        <Link to={path}>
          <StyledListItem selected={location.pathname.startsWith(path)}>
            <ListItemText classes={{ primary: 'font-medium' }}>{name}</ListItemText>
          </StyledListItem>
        </Link>
      )}

      {items && (
        <Collapse in={open}>
          <List className='ml-3 py-0'>
            {items?.map((sub, index) => (
              <MenuItem key={index} {...sub} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const Menu = () => {
  const {
    home,
    user,
    item,
    ref,
    sale,
    submission,
    management,
    statistics,
    serverStatistics,
    eastfogAccount,
    eastfogItemStatistics,
    events,
    airdropEvent,
    syncPage,
    paymentToken,
    calendarEvents,
    calendarCategories,
    gameDashboard,
    systemConfig,
  } = privateRoute;

  const { isLoggedIn } = useSelector(profileSelector);

  return (
    <>
      <List className='flex flex-col gap-1'>
        <MenuItem {...home} />
        <MenuItem {...statistics} items={[serverStatistics, eastfogItemStatistics]} />
        <MenuItem {...eastfogAccount} />
        <MenuItem {...ref} />
        <MenuItem {...management} items={[user, item, sale, submission]} />
        <MenuItem {...paymentToken} />
        <MenuItem {...events} items={[airdropEvent, syncPage, calendarEvents, calendarCategories]} />
        <MenuItem {...gameDashboard} />
        <MenuItem {...systemConfig} />
      </List>
      {isLoggedIn && <Maintenance />}
    </>
  );
};

export default Menu;
