import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from 'react-query';
import { submissionService, queryClient } from 'services';

type PopupProps = PopupController & {
  id: string;
};

const DeleteConfirmPopup = ({ id, onClose }: PopupProps) => {
  const { mutate: deleteSubmission, isLoading } = useMutation(submissionService.deleteSubmission, {
    onSuccess: () => {
      queryClient.invalidateQueries('submissionService.fetchSubmissions');
      onClose();
    },
  });

  const handleDelete = () => {
    deleteSubmission({ id });
  };

  return (
    <>
      <DialogTitle>CONFIRMATION</DialogTitle>

      <DialogContent>
        Are you want to <span className='font-bold text-red-700'>DELETE</span> this submission?
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleDelete}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DeleteConfirmPopup;
