import { ImageOutlined } from '@mui/icons-material';
import { DesktopDateTimePicker, LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  FormHelperText,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { airdropService, dashboardService, fileService, queryClient } from 'services';
import { getBase64 } from 'utils/common';

type PopupProps = PopupController & {
  item?: AirdropEventType;
  isSubEvent: boolean;
};

const AirdropEventPopup = ({ item, isSubEvent, onClose }: PopupProps) => {
  const { id } = useParams();
  const isCreate = !item;

  const { control, handleSubmit, clearErrors, setValue, watch } = useForm({ mode: 'onChange' });

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [image, setImage] = useState<string>();
  const { type = '' } = watch();

  const { mutate: createAirdropEvent, isLoading } = useMutation(
    isCreate ? airdropService.createAirdropEvent : airdropService.changeAirdropEvent,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('airdropService.fetchAirdropEvents');
        queryClient.invalidateQueries('airdropService.getAirdropEventById');
        onClose();
      },
    },
  );

  const { data } = useQuery(
    ['dashboardService.fetchItemsEastFog', { page: 1, size: 1000 }],
    () => dashboardService.fetchItemsEastFog({ page: 1, size: 1000 }),
    { keepPreviousData: true },
  );

  const { items = [] } = data ?? {};

  const handleChangeFile = (event: any) => {
    const [file] = event.target.files;
    if (!file) return;
    getBase64(file, setImage);

    if (file) {
      setLoadingUpload(true);
      const formData = new FormData();
      formData.append('file', file);
      fileService
        .uploadFile(formData)
        .then((data) => {
          setValue('type', file.type);
          setValue(file.type.includes('video') ? 'itemVideo' : 'itemImage', data);
          setValue(file.type.includes('video') ? 'itemImage' : 'itemVideo', '');
        })
        .finally(() => setLoadingUpload(false));
      clearErrors('image');
    }
  };

  const handleSubmitEvent = () => {
    handleSubmit((values) => {
      const { fromDate, toDate, ...others } = values as AirdropEventCreateOrUpdateType;
      const fromDateSecs = fromDate ? Math.floor(new Date(fromDate).getTime() / 1000) : undefined;
      const toDateSecs = toDate ? Math.floor(new Date(toDate).getTime() / 1000) : undefined;
      createAirdropEvent({
        ...others,
        parentId: id,
        fromDate: fromDateSecs,
        toDate: toDateSecs,
      });
    })();
  };

  useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        if (key === 'itemVideo' && value !== '') setValue('type', 'video/*');
        else if (key === 'itemImage' && value !== '') setValue('type', 'image/*');
        setValue(key, value);
      });
      item.itemImage ? setImage(item.itemImage) : setImage(item.itemVideo);
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>
        {isCreate ? 'CREATE' : 'UPDATE'} AIRDROP {isSubEvent ? 'SUB EVENT' : 'EVENT'}
      </DialogTitle>

      <DialogContent>
        <Controller
          name={type.includes('video') ? 'itemVideo' : 'itemImage'}
          defaultValue=''
          control={control}
          rules={{ required: 'Image or Video is required' }}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mb-1'>
              <input hidden type='file' id='image' accept='image/*,video/*' onChange={handleChangeFile} />
              <label
                htmlFor='image'
                className='cursor-pointer flex items-center justify-center border-2 border-dashed hover:border-gray-500 rounded'
              >
                <CardMedia
                  component={type.includes('video') ? 'video' : 'div'}
                  image={image}
                  className='flex justify-center items-center bg-contain h-52 w-3/4'
                  style={{
                    ...(invalid ? { borderColor: 'red' } : {}),
                    backgroundColor: 'rgba(250, 251, 252, 1)',
                  }}
                  {...{ controls: true, autoPlay: true, muted: true, loop: true }}
                  controlsList='nodownload'
                >
                  {!image && <ImageOutlined className='w-14 h-14' sx={{ color: '#A5A9BA' }} />}
                </CardMedia>
              </label>
              {!image && error && <FormHelperText className='text-red-700'>{error?.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12} sm={6}>
            <Controller
              name='fromDate'
              defaultValue={null}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDateTimePicker
                  value={value}
                  label='From Date'
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} fullWidth error={invalid} />}
                  inputFormat='dd/MM/yyyy HH:mm'
                  disablePast
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='toDate'
              defaultValue={null}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDateTimePicker
                  value={value}
                  onChange={onChange}
                  label='To Date'
                  renderInput={(props) => (
                    <TextField {...props} fullWidth error={invalid} helperText={error?.message} />
                  )}
                  inputFormat='dd/MM/yyyy HH:mm'
                  disablePast
                />
              )}
            />
          </Grid>
        </Grid>
        <div className='mt-6 flex flex-col gap-6'>
          {isSubEvent ? (
            <Controller
              name='name'
              defaultValue={null}
              control={control}
              rules={{ required: 'Item is required' }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <Autocomplete
                  value={value}
                  onChange={(event, item: ItemEastFog) => {
                    onChange({ target: { value: item.name } });
                    setValue('itemId', item.itemId);
                  }}
                  options={items ?? []}
                  disableClearable
                  getOptionLabel={(option: ItemEastFog) => option.name ?? option}
                  isOptionEqualToValue={(option: ItemEastFog, value: any) => option === value || option.name === value}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      required
                      label='Item'
                      error={invalid}
                      helperText={error?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}
            />
          ) : (
            <Controller
              name='name'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth required label='Name' error={invalid} helperText={error?.message} />
              )}
            />
          )}

          <Controller
            name='description'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                multiline
                fullWidth
                label='Description'
                minRows={2}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name='condition'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label='Condition' error={invalid} helperText={error?.message} />
            )}
          />
          {!isSubEvent && (
            <Controller
              name='joinLink'
              defaultValue=''
              control={control}
              rules={{ required: 'Join link is required' }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth label='Join Link' error={invalid} helperText={error?.message} />
              )}
            />
          )}
          {isSubEvent && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='onchainId'
                  defaultValue=''
                  control={control}
                  rules={{ required: 'On Chain ID is required' }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField {...field} fullWidth label='On Chain ID' error={invalid} helperText={error?.message} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='whitelistContract'
                  defaultValue=''
                  control={control}
                  rules={{ required: 'Whitelist Contract is required' }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Whitelist Contract'
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} disabled={loadingUpload} onClick={handleSubmitEvent}>
          {isCreate ? 'Create' : 'Update'} {isSubEvent ? 'Sub Event' : 'Event'}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AirdropEventPopup;
