import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Dialog } from '@mui/material';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { calendarService } from 'services';
import { useState } from 'react';
import { CalendarCategoryPopup, CalendarCategorySearch, DeleteConfirmPopup } from 'views/CalendarCategory';

const CalendarCategoryList = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching } = useQuery(
    ['calendarService.fetchCategoriesEvent', dataSearch],
    () => calendarService.fetchCategoriesEvent(dataSearch),
    { keepPreviousData: true },
  );

  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [calendarCategoryChoice, setCalendarCategoryChoice] = useState<CategoryEventType>();

  return (
    <>
      <div className='flex items-center justify-between'>
        <CalendarCategorySearch onChange={onSearchChange} />
        <Button variant='contained' onClick={() => setOpenCreatePopup(true)}>
          Create
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Background</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align='center'>{item.name}</TableCell>
                  <TableCell align='center'>{item.description}</TableCell>
                  <TableCell align='center'>
                    <div className='flex items-center justify-center space-x-2'>
                      <div style={{ width: '1rem', height: '1rem', backgroundColor: item.color }}></div>
                      <div>{item.color}</div>
                    </div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='flex items-center justify-center space-x-2'>
                      <div style={{ width: '1rem', height: '1rem', backgroundColor: item.background }}></div>
                      <div>{item.background}</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className='flex items-center space-x-2 justify-center'>
                      <Button
                        variant='contained'
                        size='medium'
                        color='warning'
                        onClick={() => {
                          setOpenEditPopup(true);
                          setCalendarCategoryChoice(item);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        variant='contained'
                        color='error'
                        size='medium'
                        onClick={() => {
                          setOpenDeletePopup(true);
                          setCalendarCategoryChoice(item);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && data?.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{data?.length ?? 0} Calendar Categories</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <Dialog open={openCreatePopup} fullWidth maxWidth='sm'>
        <CalendarCategoryPopup onClose={() => setOpenCreatePopup(false)} />
      </Dialog>

      <Dialog open={openEditPopup} fullWidth maxWidth='sm'>
        <CalendarCategoryPopup item={calendarCategoryChoice} onClose={() => setOpenEditPopup(false)} />
      </Dialog>

      <Dialog open={openDeletePopup} fullWidth maxWidth='sm'>
        <DeleteConfirmPopup id={calendarCategoryChoice?.id!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>
    </>
  );
};

export default CalendarCategoryList;
