import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, TextField } from '@mui/material';
import { InputNumber } from 'components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { eventService, itemService } from 'services';
import { TableMarketplace, TableMetaverse, TableNft, TableItem } from 'views/SyncPage';

const SYNC_TYPE = [
  { label: 'Sync Metaverse', code: 'metaverse' },
  { label: 'Sync Marketplace', code: 'marketplace' },
  { label: 'Sync NFT Transfer', code: 'transfer' },
  { label: 'Sync Items', code: 'item' },
];

const SYNC_ITEM_TYPE = [
  { label: 'By Address', code: 'address' },
  { label: 'By Token ID', code: 'tokenId' },
];

const SyncPage = () => {
  const { control, handleSubmit, watch, setValue } = useForm({ mode: 'onChange' });

  const { type = SYNC_TYPE[0].code, itemSyncType = SYNC_ITEM_TYPE[0].code, fromBlock, toBlock } = watch();

  const [dataSync, setDataSync] = useState<any[]>([]);

  const { mutate: syncMetaverse, isLoading: isLoadingMetaverse } = useMutation(eventService.syncMetaverse, {
    onSuccess: (data) => {
      setDataSync(data);
    },
  });

  const { mutate: syncMarketplace, isLoading: isLoadingMarketplace } = useMutation(eventService.syncMarketplace, {
    onSuccess: (data) => {
      setDataSync(data);
    },
  });

  const { mutate: syncTransfer, isLoading: isLoadingTransfer } = useMutation(eventService.syncTransfer, {
    onSuccess: (data) => {
      setDataSync(data);
    },
  });

  const { mutate: syncItemByAddress, isLoading: isLoadingItemByAddress } = useMutation(itemService.syncItemByAddress, {
    onSuccess: (data) => {
      setDataSync(data);
    },
  });

  const { mutate: syncItemByTokenID, isLoading: isLoadingItemByTokenID } = useMutation(itemService.syncItemByTokenID, {
    onSuccess: () => {},
  });

  const handleClickSubmit = () => {
    if (!fromBlock && !toBlock) {
      setValue('fromBlock', 0);
      setValue('toBlock', 0);
    }

    if (toBlock < fromBlock) setValue('toBlock', fromBlock);

    const mutate = [syncMetaverse, syncMarketplace, syncTransfer][
      ['metaverse', 'marketplace', 'transfer'].indexOf(type)
    ];
    handleSubmit(({ fromBlock, toBlock, address, tokenId }) => {
      if (type === 'item') itemSyncType === 'address' ? syncItemByAddress({ address }) : syncItemByTokenID({ tokenId });
      else mutate({ fromBlock, toBlock });
    })();
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12} className='space-x-4'>
          <Controller
            name='type'
            defaultValue={SYNC_TYPE[0].code}
            control={control}
            render={({ field }) => (
              <TextField select {...field} label='Type'>
                {SYNC_TYPE.map((item, index) => (
                  <MenuItem key={index} value={item.code}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          {type === 'item' && (
            <Controller
              name='itemSyncType'
              defaultValue={SYNC_ITEM_TYPE[0].code}
              control={control}
              render={({ field }) => (
                <TextField select {...field} label='Item Sync Type'>
                  {SYNC_ITEM_TYPE.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          )}
        </Grid>
        {type === 'item' ? (
          <Grid item sm={3}>
            {itemSyncType === 'address' ? (
              <Controller
                name='address'
                defaultValue=''
                control={control}
                rules={{ required: 'Address is required' }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label='Address'
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            ) : (
              <Controller
                name='tokenId'
                defaultValue=''
                control={control}
                rules={{ required: 'Token ID is required' }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label='Token ID'
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          </Grid>
        ) : (
          <>
            <Grid item sm={3}>
              <Controller
                name='fromBlock'
                defaultValue={0}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label='From Block'
                    InputProps={{
                      inputComponent: InputNumber as any,
                      inputProps: {
                        decimalScale: 0,
                      },
                    }}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={3}>
              <Controller
                name='toBlock'
                defaultValue={0}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label='To Block'
                    InputProps={{
                      inputComponent: InputNumber as any,
                      inputProps: {
                        decimalScale: 0,
                      },
                    }}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item sm={12}>
          <LoadingButton
            variant='outlined'
            size='large'
            className='w-28'
            loading={
              isLoadingMetaverse ||
              isLoadingMarketplace ||
              isLoadingTransfer ||
              isLoadingItemByAddress ||
              isLoadingItemByTokenID
            }
            onClick={handleClickSubmit}
          >
            Sync
          </LoadingButton>
        </Grid>

        <Grid item sm={12}>
          {type === 'metaverse' && <TableMetaverse dataSync={dataSync} />}
          {type === 'marketplace' && <TableMarketplace dataSync={dataSync} />}
          {type === 'transfer' && <TableNft dataSync={dataSync} />}
          {type === 'item' && itemSyncType === 'address' && <TableItem dataSync={dataSync} />}
        </Grid>
      </Grid>
    </>
  );
};

export default SyncPage;
