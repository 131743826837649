import { CardMedia, Grid, Paper, IconButton, Menu, MenuItem, Dialog } from '@mui/material';
import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import { useAnchor } from 'hooks';
import { useState } from 'react';
import { DeleteConfirmPopup, AirdropEventPopup } from 'views/Airdrop';

const CardAirdropItem = ({ item, event }: { item: AirdropEventType; event: AirdropEventType }) => {
  const [anchorSubEventSettings, openSubEventSettings, onOpenSubEventSettings, onCloseSubEventSettings] = useAnchor();

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [eventChoice, setEventChoice] = useState<AirdropEventType>();

  return (
    <Paper className='md:p-10 p-4 rounded-3xl shadow-md relative'>
      <div className='absolute top-0 right-0 p-4'>
        <IconButton onClick={onOpenSubEventSettings}>
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={anchorSubEventSettings}
          open={openSubEventSettings}
          onClose={onOpenSubEventSettings}
          onClick={onCloseSubEventSettings}
        >
          <MenuItem
            onClick={() => {
              setEventChoice(event);
              setOpenEditPopup(true);
            }}
          >
            <Edit fontSize='small' />
            <span className='ml-2'>Edit</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setEventChoice(event);
              setOpenDeletePopup(true);
            }}
          >
            <Delete fontSize='small' />
            <span className='ml-2'>Delete</span>
          </MenuItem>
        </Menu>
      </div>
      <Grid container spacing={5}>
        <Grid item md={5.5} xs={12}>
          {event.itemVideo ? (
            <CardMedia
              component='video'
              image={event.itemVideo}
              className='rounded-2xl mx-auto'
              playsInline
              autoPlay
              loop
              muted
              controls
              controlsList='nodownload'
              sx={{ minHeight: 240, maxHeight: 360 }}
            />
          ) : (
            <CardMedia image={event.itemImage} className='bg-contain h-full' sx={{ minHeight: 240, maxHeight: 360 }} />
          )}
        </Grid>
        <Grid item md={6.5} xs={12} className='flex flex-col items-start gap-3'>
          <div className='bg-blue-400 text-white font-black rounded-lg px-3 py-1'>{item.name}</div>
          <div className='font-black text-2xl text-secondary-main'>{event.name}</div>

          <div className='font-semibold text-base whitespace-pre-line'>{event.description}</div>
          <div className='font-bold text-primary-main'>{event.condition}</div>

          <Grid container>
            <Grid item sm={3} className='text-secondary-main'>
              On Chain ID
            </Grid>
            <Grid item sm={9}>
              : {event.onchainId}
            </Grid>
            <Grid item sm={3} className='text-secondary-main'>
              Whitelist Contract
            </Grid>
            <Grid item sm={9}>
              : {event.whitelistContract}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={openDeletePopup} fullWidth maxWidth='xs'>
        <DeleteConfirmPopup id={eventChoice?.id!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>

      <Dialog open={openEditPopup} fullWidth maxWidth='sm'>
        <AirdropEventPopup isSubEvent={true} item={eventChoice} onClose={() => setOpenEditPopup(false)} />
      </Dialog>
    </Paper>
  );
};

export default CardAirdropItem;
