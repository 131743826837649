import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { systemSelector } from 'reducers/system';
import { dashboardService, queryClient } from 'services';

const MaintenanceConfirmPopup = ({ onClose }: PopupController) => {
  const { id, isMaintainceEnabled } = useSelector(systemSelector);

  const { mutate: updateSystem, isLoading } = useMutation(dashboardService.updateSystem, {
    onSuccess: () => {
      queryClient.invalidateQueries('dashboardService.fetchSystem');
      onClose();
    },
  });

  const handleChange = () => {
    updateSystem({ id, isMaintainceEnabled: !isMaintainceEnabled });
  };

  return (
    <>
      <DialogTitle>CONFIRMATION</DialogTitle>

      <DialogContent>
        Switch maintenance mode to <span className='font-bold'>{isMaintainceEnabled ? 'OFF' : 'ON'}</span>?
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleChange}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default MaintenanceConfirmPopup;
