import {
  CircularProgress,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  Button,
} from '@mui/material';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useQuery, useMutation } from 'react-query';
import { dashboardService } from 'services';
import { useState } from 'react';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { shorten } from 'utils/common';
import { DetailPopup } from 'views/Ref';

const UserList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch({ search: '' });
  const [selectedId, setSelectedId] = useState('');

  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const { data, isFetching, refetch } = useQuery(
    ['dashboardService.fetchUsers', dataSearch],
    () => dashboardService.fetchUsers(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages: totalPage } = data ?? {};

  const { mutate: setMinter, isLoading: loadingSetMinter } = useMutation(dashboardService.setMinter, {
    onSuccess: () => {
      refetch();
    },
  });

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <>
      <CommonSearch onChange={onSearchChange} />

      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Address</TableCell>
                <TableCell className='whitespace-nowrap'>Email Verified</TableCell>
                <TableCell>Minter</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='text-center whitespace-nowrap'>{item.email}</TableCell>
                  <TableCell className='text-center'>{item.username}</TableCell>
                  <TableCell className='text-center'>
                    <div className='flex items-center justify-center space-x-2'>
                      <div>{shorten(item.address)}</div>
                      {item.address && (
                        <IconButton onClick={() => copy(item.address)} title='Copy'>
                          <ContentCopyOutlined />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className='text-center capitalize'>{item.emailVerfied.toString()}</TableCell>
                  <TableCell className='text-center'>
                    {loadingSetMinter && item.id === selectedId ? (
                      <div className='pt-2'>
                        <CircularProgress size={24} />
                      </div>
                    ) : (
                      <Switch
                        checked={item.isMinter}
                        onClick={() => {
                          setSelectedId(item.id);
                          setMinter({ address: item.address, isMinter: !item.isMinter });
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      onClick={() => {
                        setSelectedUserId(item.id);
                        setOpenDetailPopup(true);
                      }}
                    >
                      Detail Refs
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{total ?? 0} Users</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openDetailPopup} fullWidth maxWidth='lg'>
        <DetailPopup userId={selectedUserId} onClose={() => setOpenDetailPopup(false)} />
      </Dialog>
    </>
  );
};

export default UserList;
