import { Chip } from '@mui/material';

const TableItem = ({ dataSync }: { dataSync: string[] }) => {
  return (
    <div className='grid gap-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-10'>
      {dataSync.map((item, index) => (
        <Chip key={item} label={item} />
      ))}
    </div>
  );
};

export default TableItem;
