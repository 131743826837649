import { client } from './axios';

const fetchSales = (params: SaleParamsType): Promise<SalePaginateType> =>
  client.get(`/dashboard-apis/api/items/list-sale`, { params });

const deleteSaleItem = ({ tokenId, ...body }: SaleDeleteType) =>
  client.delete(`/dashboard-apis/api/items/delete-sale/${tokenId}`, { data: body });

export default {
  fetchSales,
  deleteSaleItem,
};
