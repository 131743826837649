import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { calendarService, queryClient } from 'services';
import { ChromePicker } from 'react-color';

type PopupProps = PopupController & {
  item?: CategoryEventType;
};

const CalendarCategoryPopup = ({ item, onClose }: PopupProps) => {
  const isCreate = !item;

  const { control, handleSubmit, setValue, watch } = useForm({ mode: 'onChange' });

  const { color = '#000', background = '#000' } = watch();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayBackgroundPicker, setDisplayBackgroundPicker] = useState(false);

  const { mutate: createCalendarCategory, isLoading } = useMutation(
    isCreate ? calendarService.createCalendarCategory : calendarService.updateCalendarCategory,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('calendarService.fetchCategoriesEvent');
        onClose();
      },
    },
  );

  const handleSubmitEvent = () => {
    handleSubmit((values) => {
      createCalendarCategory({
        ...values,
        categoryId: item?.id,
      });
    })();
  };

  useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>{isCreate ? 'CREATE' : 'UPDATE'} CALENDAR CATEGORY</DialogTitle>

      <DialogContent>
        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth required label='Name' error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='description'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  multiline
                  fullWidth
                  label='Description'
                  minRows={2}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <div className='flex items-center space-x-4'>
              <Controller
                name='color'
                defaultValue={color}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} required label='Color' error={invalid} helperText={error?.message} />
                )}
              />

              <div>
                <Button variant='outlined' onClick={() => setDisplayColorPicker((prev) => !prev)}>
                  Pick Color
                </Button>

                {displayColorPicker ? (
                  <div className='absolute right-4 top-1/2 z-10'>
                    <div className='fixed inset-0' onClick={() => setDisplayColorPicker(false)} />
                    <ChromePicker color={color} onChange={(color) => setValue('color', color.hex)} />
                  </div>
                ) : null}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className='flex items-center space-x-4'>
              <Controller
                name='background'
                defaultValue={background}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} required label='Background' error={invalid} helperText={error?.message} />
                )}
              />

              <div>
                <Button variant='outlined' onClick={() => setDisplayBackgroundPicker((prev) => !prev)}>
                  Pick Color
                </Button>

                {displayBackgroundPicker ? (
                  <div className='absolute right-4 top-1/2 z-10'>
                    <div className='fixed inset-0' onClick={() => setDisplayBackgroundPicker(false)} />
                    <ChromePicker color={background} onChange={(color) => setValue('background', color.hex)} />
                  </div>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleSubmitEvent}>
          {isCreate ? 'Create' : 'Update'}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CalendarCategoryPopup;
