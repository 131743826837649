import { Button, debounce, Grid, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

const CalendarCategorySearch = ({ onChange }: SearchController) => {
  const [name, setName] = useState('');
  const [color, setColor] = useState('');
  const [params, setParams] = useState({});

  const handleClickReset = () => {
    setName('');
    setColor('');
    setParams({ name: '', color: '' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeParams = useCallback(
    debounce((values) => {
      setParams((params) => ({ ...params, ...values }));
    }, 300),
    [],
  );

  useEffect(() => {
    onChange({ ...params });
  }, [onChange, params]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={3}>
        <TextField
          fullWidth
          label='Name'
          value={name}
          onChange={(event) => {
            setName(event.target.value);
            debounceChangeParams({ name: event.target.value });
          }}
        />
      </Grid>

      <Grid item sm={3}>
        <TextField
          fullWidth
          label='Color'
          value={color}
          onChange={(event) => {
            setColor(event.target.value);
            debounceChangeParams({ color: event.target.value });
          }}
        />
      </Grid>

      <Grid item sm={3}>
        <Button variant='outlined' className='w-28' size='large' onClick={handleClickReset}>
          Clear
        </Button>
      </Grid>
    </Grid>
  );
};

export default CalendarCategorySearch;
