import { Tooltip, Skeleton, Avatar, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

type LeaderBoardProps = {
  title: string;
  list: GamePlayerType[];
  color: string;
  loading: boolean;
  unit?: string;
  tooltip?: string;
};

type LeaderBoardItemProps = {
  classes: any;
  nthTop: number;
  name: string;
  score: number;
  unit?: string;
};

const LeaderBoardItem = ({ classes, nthTop, name, score, unit }: LeaderBoardItemProps) => {
  const nameReduced = Boolean(name) && name.length >= 20 ? `${name.slice(0, 17)}...` : name;
  const color = nthTop === 1 ? '#FFAA00' : nthTop === 2 ? '#C0C0C0' : nthTop === 3 ? '#cd7f32' : '#EC4B76';

  return (
    <div
      className='flex justify-between items-center px-4 py-2 border'
      style={{ borderColor: nthTop <= 3 ? color : '' }}
    >
      <Avatar />

      <div className={classes.content}>
        <Typography variant='h3'>{nameReduced}</Typography>
        <div>
          {score}&nbsp;{unit}
        </div>
      </div>

      <div className={classes.nthTop} style={{ backgroundColor: color }}>
        {nthTop}
      </div>
    </div>
  );
};

const LeaderBoard = ({ title, list, color, loading, unit, tooltip }: LeaderBoardProps) => {
  const classes = useStyles({ color });
  const sortedList = list && list.sort((a, b) => Number(b.wonPoint) - Number(a.wonPoint));

  return (
    <div className={classes.root}>
      <div className={`${classes.titleWrap} flex justify-between items-center`}>
        <Typography variant='h3' className='capitalize text-white text-2xl'>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement='top'>
            <Info className='text-white cursor-pointer' />
          </Tooltip>
        )}
      </div>

      <div className={classes.boxWrap}>
        {!loading
          ? list &&
            sortedList.map((item, index) => (
              <LeaderBoardItem
                name={item.playerName}
                score={item.wonPoint}
                unit={unit}
                classes={classes}
                key={index}
                nthTop={index + 1}
              />
            ))
          : new Array(10)
              .fill(0)
              .map((_, index) => (
                <Skeleton className={classes.skeleton} key={index} animation='wave' variant='rectangular' />
              ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '40rem',
    backgroundColor: '#fcfcfc',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 1px 12px 4px rgb(0 0 0 / 8%)',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',

    '&::-webkit-scrollbar': {
      width: '2px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fcfcfc',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--color-primary-main)',
    },
  },

  titleWrap: {
    width: '100%',
    backgroundColor: (props: any) => props.color,
    padding: '1.8rem 1.2rem',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2,
  },

  boxWrap: {
    padding: '1.8rem 1.4rem',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '1.4rem',
  },

  skeleton: {
    height: '5rem',
  },

  content: {
    flexGrow: 1,
    margin: '0 1.2rem',
  },

  nthTop: {
    width: '4rem',
    height: '4rem',
    lineHeight: '4rem',
    textAlign: 'center',
    fontSize: '1.6rem',
    fontWeight: 800,
    color: '#fff',
    clipPath: 'polygon(50% 0, 100% 30%, 100% 70%, 50% 100%, 0 70%, 0 30%)',
  },
}));

export default LeaderBoard;
