import {
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  IconButton,
} from '@mui/material';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { dashboardService } from 'services';
import { Spinner, TableRowEmpty } from 'components';
import { shorten } from 'utils/common';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

const TIME_RANGE_FILTERS = [
  { value: { days: 1 }, label: 'Last 24 hours', code: 'LAST_24_HOURS' },
  { value: { days: 7 }, label: 'Last 7 days', code: 'LAST_7_DAYS' },
  { value: { days: 30 }, label: 'Last 30 days', code: 'LAST_30_DAYS' },
  { value: { years: 1 }, label: 'Last year', code: 'LAST_YEAR' },
];

const Home = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch } = useForm();

  const [dataSearch, onSearchChange] = useSearch({
    fromDate: Math.floor(DateTime.now().minus(TIME_RANGE_FILTERS[3].value).toSeconds()),
    toDate: Math.floor(DateTime.now().toSeconds()),
  });

  const { data, isFetching } = useQuery(
    ['dashboardService.fetchDashboard', dataSearch],
    () => dashboardService.fetchDashboard(dataSearch),
    { keepPreviousData: true },
  );

  const { history } = data ?? {};
  const { items = [], total, currentPage, pages: totalPage } = history ?? {};

  useEffect(() => {
    const subscription = watch(({ timeRangeType }, { name, type }) => {
      if (type) {
        if (name === 'timeRangeType') {
          if (timeRangeType) {
            onSearchChange({
              fromDate: Math.floor(
                DateTime.now()
                  .minus(TIME_RANGE_FILTERS.find((item) => item.code === timeRangeType)?.value ?? { days: 1 })
                  .toSeconds(),
              ),
              toDate: Math.floor(DateTime.now().toSeconds()),
            });
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onSearchChange]);

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <Controller
            name='timeRangeType'
            defaultValue={TIME_RANGE_FILTERS[3].code}
            control={control}
            render={({ field }) => (
              <TextField select {...field} fullWidth>
                {TIME_RANGE_FILTERS.map((item, index) => (
                  <MenuItem key={index} value={item.code}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>

      <Paper variant='outlined' className='my-6'>
        <Grid container>
          {[
            { label: 'NUMBER OF TRACE', value: data?.numberOfTrace },
            { label: 'TOTAL VALUE', value: data?.totalVolume!.toFixed(2) || 0 },
            { label: 'AVERAGE PRICE', value: data?.avgPrice! || 0 },
            { label: 'FLOOR PRICE', value: data?.floorPrice.toFixed(2) },
            { label: 'CEILING PRICE', value: data?.ceilingPrice },
          ].map((item, index) => (
            <Grid item key={index} xs={12} sm={6} xl={2.4} className='p-8'>
              <Typography variant='h3' color='primary' className='mb-4'>
                {item.label}
              </Typography>
              <Typography variant='h1'>{item.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Grid container>
        <Grid item sm={12}>
          <TableContainer component={Paper}>
            <Spinner loading={isFetching}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Token ID</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>From Address</TableCell>
                    <TableCell>To Address</TableCell>
                    <TableCell>NFT Contract</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Item Type</TableCell>
                    <TableCell>Transaction Hash</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((element) => (
                    <TableRow key={element.id}>
                      <TableCell className='whitespace-nowrap'>{element.item.name}</TableCell>
                      <TableCell>{element.tokenId}</TableCell>
                      <TableCell>
                        {element.price.toFixed(2)} {element.paymentToken?.name ?? 'MATIC'}
                      </TableCell>
                      <TableCell>
                        <div className='flex items-center space-x-2'>
                          <div>{shorten(element.fromAddress)}</div>
                          {element.fromAddress && (
                            <IconButton onClick={() => copy(element.fromAddress)} title='Copy'>
                              <ContentCopyOutlined />
                            </IconButton>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='flex items-center space-x-2'>
                          <div>{shorten(element.toAddress)}</div>
                          {element.toAddress && (
                            <IconButton onClick={() => copy(element.toAddress)} title='Copy'>
                              <ContentCopyOutlined />
                            </IconButton>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='flex items-center space-x-2'>
                          <div>{shorten(element.nftContract)}</div>
                          {element.nftContract && (
                            <IconButton onClick={() => copy(element.nftContract)} title='Copy'>
                              <ContentCopyOutlined />
                            </IconButton>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{element.item.amount}</TableCell>
                      <TableCell>{element.item.type}</TableCell>
                      <TableCell>
                        <div className='flex items-center space-x-2'>
                          <div>{shorten(element.item.transactionHash)}</div>
                          {element.item.transactionHash && (
                            <IconButton onClick={() => copy(element.item.transactionHash)} title='Copy'>
                              <ContentCopyOutlined />
                            </IconButton>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRowEmpty visible={!isFetching && items.length === 0} />
                </TableBody>
                <caption className='font-bold border-t'>{total ?? 0} Histories</caption>
              </Table>
            </Spinner>
          </TableContainer>

          <div className='flex justify-center'>
            <Pagination
              page={currentPage ?? 1}
              count={totalPage}
              onChange={(event, value) => onSearchChange({ page: value })}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
