import { TextField, MenuItem, Grid, Typography, Divider } from '@mui/material';
import { useQuery } from 'react-query';
import { dashboardService } from 'services';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { LeaderBoard } from './components';

const TOP_PLAYER_FILTERS = [
  { label: '3 Players', code: 3 },
  { label: '5 Players', code: 5 },
  { label: '10 Players', code: 10 },
];

const COLORS = ['#847AD1', '#7AD18A', '#E06B96', '#36C0CE', '#DB883E'];

const GameDashboard = () => {
  const [topPlayerCount, setTopPlayerCount] = useState(TOP_PLAYER_FILTERS[0].code);

  const { data: minigamePlayers, isFetching: isMinigameFetching } = useQuery(
    ['dashboardService.fetchMinigameLeaderBoard', { topPlayerCount }],
    () => dashboardService.fetchMinigameLeaderBoard({ topPlayerCount }),
    { keepPreviousData: true },
  );

  const { data: battleRoyalePlayers, isFetching: isBattleRoyaleFetching } = useQuery(
    ['dashboardService.fetchBattleRoyaleLeaderBoard', { topPlayerCount }],
    () => dashboardService.fetchBattleRoyaleLeaderBoard({ topPlayerCount }),
    { keepPreviousData: true },
  );

  const { control, watch } = useForm();

  useEffect(() => {
    const subscription = watch(({ topPlayerCount }, { name, type }) => {
      if (name === 'topPlayerCount') {
        if (topPlayerCount) {
          setTopPlayerCount(
            TOP_PLAYER_FILTERS.find((item) => item.code === topPlayerCount)?.code ?? TOP_PLAYER_FILTERS[0].code,
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setTopPlayerCount]);

  return (
    <>
      <Controller
        name='topPlayerCount'
        defaultValue={TOP_PLAYER_FILTERS[0].code}
        control={control}
        render={({ field }) => (
          <TextField select {...field}>
            {TOP_PLAYER_FILTERS.map((item, index) => (
              <MenuItem key={index} value={item.code}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <div className='flex items-center justify-center my-4'>
        <img src={require('assets/icons/icon-top-profile.png').default} alt='' className='mr-4' />
        <Typography variant='h1' className='font-bold' color='primary'>
          LEADERBOARD
        </Typography>
      </div>

      <Divider className='mb-4' />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} xl={3}>
          <LeaderBoard
            title='TOP MINIGAME'
            color={COLORS[0]}
            list={minigamePlayers!}
            loading={isMinigameFetching}
            tooltip='Top players minigame with the highest score'
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <LeaderBoard
            title='TOP BATTLE ROYALE'
            color={COLORS[1]}
            list={battleRoyalePlayers!}
            loading={isBattleRoyaleFetching}
            tooltip='Top players battle royale with the highest score'
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GameDashboard;
