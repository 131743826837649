import { ImageOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import { web3 } from 'contracts';
import 'quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useQuill } from 'react-quilljs';
import { fileService, queryClient, submissionService } from 'services';
import { getBase64 } from 'utils/common';

type PopupProps = PopupController & {
  item?: SubmissionType;
};

const SubmissionPopup = ({ item, onClose }: PopupProps) => {
  const isCreate = !item;

  const { control, handleSubmit, setValue, clearErrors } = useForm({ mode: 'onChange' });

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [image, setImage] = useState<string>();

  const [description, setDescription] = useState(item?.description);
  const { quill, quillRef } = useQuill();
  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(item?.description ?? '');
      quill.on('text-change', () => {
        setDescription(quill.root.innerHTML);
      });
    }
  }, [quill, item]);

  const { mutate: createSubmission, isLoading } = useMutation(
    isCreate ? submissionService.createSubmission : submissionService.updateSubmission,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('submissionService.fetchSubmissions');
        onClose();
      },
    },
  );

  const handleChangeFile = (event: any) => {
    const [file] = event.target.files;
    if (!file) return;
    getBase64(file, setImage);

    if (file) {
      setLoadingUpload(true);
      const formData = new FormData();
      formData.append('file', file);
      fileService
        .uploadFile(formData)
        .then((data) => {
          setValue('imageUrl', data);
        })
        .finally(() => setLoadingUpload(false));
      clearErrors('image');
    }
  };

  const handleSubmitEvent = () => {
    handleSubmit((values) => {
      createSubmission({
        id: item?.id,
        ...(values as SubmissionCreateType),
        description,
      });
    })();
  };

  useEffect(() => {
    if (item) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
      setImage(item.imageUrl);
    }
  }, [setValue, item]);

  return (
    <>
      <DialogTitle>{isCreate ? 'CREATE' : 'UPDATE'} SUBMISSION</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='imageUrl'
              defaultValue=''
              control={control}
              rules={{ required: 'Image or Video is required' }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth className='mb-1'>
                  <input hidden type='file' id='image' accept='image/*' onChange={handleChangeFile} />
                  <label
                    htmlFor='image'
                    className='cursor-pointer flex items-center justify-center border-2 border-dashed hover:border-gray-500 rounded'
                  >
                    <CardMedia
                      image={image}
                      className='flex justify-center items-center bg-contain h-52 w-3/4'
                      style={{
                        ...(invalid ? { borderColor: 'red' } : {}),
                        backgroundColor: 'rgba(250, 251, 252, 1)',
                      }}
                    >
                      {!image && <ImageOutlined className='w-14 h-14' sx={{ color: '#A5A9BA' }} />}
                    </CardMedia>
                  </label>
                  {!image && error && <FormHelperText className='text-red-700'>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} multiline fullWidth label='Name' error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='creator'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth label='Creator' error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='nftContract'
              defaultValue=''
              control={control}
              rules={{
                validate: {
                  isAddress: (value) => web3.utils.isAddress(value) || 'Must be valid address',
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth label='NFT Contract' error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <div style={{ height: 200 }}>
              <div ref={quillRef} />
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} disabled={loadingUpload} onClick={handleSubmitEvent}>
          {isCreate ? 'Create' : 'Update'} Submission
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default SubmissionPopup;
