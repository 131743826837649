import { Dialog, Drawer, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAnchor, useWindowSize } from 'hooks';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useState } from 'react';
import { Edit, MoreHoriz, Delete } from '@mui/icons-material';
import { DeleteConfirmPopup, CalendarEventPopup } from 'views/CalendarEvents';

const Popup = ({
  isOpenPopup,
  setIsOpenPopup,
  dataPopup,
}: {
  isOpenPopup: boolean;
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  dataPopup: any;
}) => {
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const { isMobile } = useWindowSize();

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [eventCalendarChoice, setEventCalendarChoice] = useState<EventType>();

  const Content = () => {
    const [anchorSubEventSettings, openSubEventSettings, onOpenSubEventSettings, onCloseSubEventSettings] = useAnchor();

    return (
      <div className='p-6 md:p-0'>
        <div className='flex flex-col'>
          <div className='flex flex-row justify-between'>
            <div className='flex'>
              <div className={`w-2 h-12 rounded-2xl mr-4`} style={{ background: `${dataPopup.category.color}` }}></div>
              <div className='flex-col'>
                <div
                  className='whitespace-nowrap font-bold md:text-sm text-xs leading-5'
                  style={{ color: `${dataPopup.category.color}` }}
                >
                  {dataPopup.category.name}
                </div>
                <div className='font-extrabold md:text-xl text-base leading-7'>{dataPopup.title}</div>
              </div>
            </div>
            <IconButton onClick={onOpenSubEventSettings}>
              <MoreHoriz />
            </IconButton>
            <Menu
              anchorEl={anchorSubEventSettings}
              open={openSubEventSettings}
              onClose={onOpenSubEventSettings}
              onClick={onCloseSubEventSettings}
            >
              <MenuItem
                onClick={() => {
                  setEventCalendarChoice(dataPopup);
                  setOpenEditPopup(true);
                }}
              >
                <Edit fontSize='small' />
                <span className='ml-2'>Edit</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setEventCalendarChoice(dataPopup);
                  setOpenDeletePopup(true);
                }}
              >
                <Delete fontSize='small' />
                <span className='ml-2'>Delete</span>
              </MenuItem>
            </Menu>
          </div>

          <div className='pt-6 flex flex-row'>
            <div className='rounded-2xl w-full bg-gray-200'>
              <div className='py-4 h-16 px-4 flex flex-row'>
                <div className='text-xs text-center mx-auto pt-1 leading-4'>
                  <span className='font-bold'>Start Date</span>
                  <span className='font-bold pl-2 md:text-xl text-base leading-7'>
                    {DateTime.fromJSDate(dataPopup.start).toFormat('dd MMM')}
                  </span>
                </div>
                <div className={'w-px h-full bg-gray-400 mx-auto'}></div>
                <div className='text-xs text-center pt-1 mx-auto leading-4 '>
                  <span className='font-bold'>End Date</span>
                  <span className='font-bold pl-2 md:text-xl text-base leading-7'>
                    {DateTime.fromJSDate(dataPopup.end).toFormat('dd MMM')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <img
            src={dataPopup.bannerUrl}
            alt='banner'
            onLoad={() => {
              setIsLoadingImg(true);
            }}
            className={`w-full ${isLoadingImg === false && 'hidden'}} rounded-2xl mt-6`}
          />
          {isLoadingImg ? (
            <></>
          ) : (
            <>
              <Skeleton sx={{ bgcolor: 'grey.900' }} variant='rectangular' width='100%' className='h-44 rounded-2xl' />
            </>
          )}

          <div className='md:text-sm text-xs mt-6 truncate whitespace-pre-line overflow-hidden text-ellipsis my-6 leading-4 md:leading-5 text-black'>
            {dataPopup.detail}
          </div>
          <div
            className={`border-dashed border-2 rounded-2xl border-[#DEDEDE] ${
              dataPopup.prize === '' && dataPopup.howToWin === '' ? 'hidden ' : ''
            } mb-4 w-full`}
          >
            <div
              className={`flex flex-row mx-4 md:mx-6 ${dataPopup.prize === '' && 'hidden'} md:mt-6 ${
                dataPopup.howToWin === '' && 'md:mb-4 mb-4'
              } mt-4`}
            >
              <img
                src={require('../assets/ic_prize.svg').default}
                alt='bell'
                className='md:w-14 md:h-14 w-12 h-12 cursor-pointer'
              />
              <div className='flex-col ml-4'>
                <div className='whitespace-nowrap text-left font-bold md:text-sm text-xs leading-4'>Prize</div>
                <div className='md:text-sm text-xs md:w-80 w-full leading-4 whitespace-pre-line'>{dataPopup.prize}</div>
              </div>
            </div>
            <div className={`flex flex-row ${dataPopup.howToWin === '' && 'hidden'} md:mx-6 md:my-6 mx-4 mt-4`}>
              <img
                src={require('../assets/ic_wining.svg').default}
                alt='bell'
                className='md:w-14 md:h-14 w-12 h-12 cursor-pointer'
              />
              <div className={`flex-col ml-4`}>
                <div className='whitespace-nowrap font-bold md:text-sm text-xs leading-4'>Winning</div>
                <div className='md:text-sm text-xs leading-4 md:w-72 w-full h-10 whitespace-pre-line break-all'>
                  {dataPopup.howToWin}
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-row pb-1 justify-between w-full'>
            <div className='w-1/3 pr-4'>
              <LoadingButton
                fullWidth
                color='inherit'
                variant='contained'
                size='large'
                onClick={(e) => {
                  setIsOpenPopup(false);
                  setIsLoadingImg(false);
                }}
              >
                CLOSE
              </LoadingButton>
            </div>
            <div className={`w-8/12 ${dataPopup.externalUrl === '' && 'hidden'}`}>
              <LoadingButton
                fullWidth
                color='info'
                variant='contained'
                size='large'
                onClick={(e) => {
                  window.open(dataPopup.externalUrl);
                }}
              >
                SEE MORE
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        <Drawer
          anchor={'bottom'}
          open={isOpenPopup}
          BackdropProps={{ sx: { background: 'rgba(219, 241, 255, 0.7)' } }}
          PaperProps={{ sx: { borderTopLeftRadius: '24px', borderTopRightRadius: '24px' } }}
          onClose={() => {
            setIsOpenPopup(false);
            setIsLoadingImg(false);
          }}
        >
          <Content />
        </Drawer>
      ) : (
        <Dialog
          open={isOpenPopup}
          onClose={() => setIsOpenPopup(false)}
          hideBackdrop
          PaperProps={{
            sx: {
              margin: 0,
              width: '30rem',
              background: 'white',
              borderRadius: '24px',
              boxShadow: '0px 12px 16px rgba(143, 209, 236, 0.25)',
              padding: 4,
              position: 'relative',
            },
          }}
        >
          <Content />
        </Dialog>
      )}

      <Dialog open={openDeletePopup} fullWidth maxWidth='xs'>
        <DeleteConfirmPopup
          id={eventCalendarChoice?.id!}
          onClose={() => {
            setOpenDeletePopup(false);
            setIsOpenPopup(false);
          }}
        />
      </Dialog>

      <Dialog open={openEditPopup} fullWidth maxWidth='md'>
        <CalendarEventPopup
          item={eventCalendarChoice}
          onClose={() => {
            setOpenEditPopup(false);
            setIsOpenPopup(false);
          }}
        />
      </Dialog>
    </>
  );
};
export default Popup;
