import { LoadingButton, DesktopDateTimePicker } from '@mui/lab';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  OutlinedInput,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { dashboardService } from 'services';
import { DateTime } from 'luxon';

const EXPORT_FIELDS = ['username', 'address', 'email', 'email_confirmed', 'whitelist'];

type ExportPopupProps = PopupController & {
  paramsQueries: Object;
};

const ExportPopup = ({ paramsQueries, onClose }: ExportPopupProps) => {
  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });

  const { fields = EXPORT_FIELDS } = watch();

  const { mutate, isLoading } = useMutation(dashboardService.exportAccount, {
    onSuccess: (res) => {
      const blob = new Blob([res], { type: 'text/csv;charset=UTF-8' });
      const blobURL = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'account-statistics';
      anchor.href = blobURL;
      anchor.click();
      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 100);
    },
  });

  const handleExport = () => {
    handleSubmit((values) => {
      const { fromDate, toDate, whitelist, email_confirmed } = values;
      mutate({
        ...values,
        fromDate: fromDate.toSeconds(),
        toDate: toDate.toSeconds(),
        whitelist: whitelist === 'true' ? true : whitelist === 'false' ? false : null,
        email_confirmed: email_confirmed === 'true' ? true : email_confirmed === 'false' ? false : null,
        ...paramsQueries,
      } as EastfogAccountExportType);
    })();
  };

  return (
    <>
      <DialogTitle>EXPORT</DialogTitle>

      <DialogContent>
        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12}>
            <Controller
              name='fields'
              defaultValue={fields}
              control={control}
              render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                <FormControl fullWidth>
                  <InputLabel>Export Fields</InputLabel>
                  <Select
                    multiple
                    value={value}
                    onChange={onChange}
                    label='Export Fields'
                    input={<OutlinedInput label='Export Fields' />}
                    renderValue={(selected: any) => selected.map((x: string) => x).join(', ')}
                  >
                    {EXPORT_FIELDS.map((field) => (
                      <MenuItem key={field} value={field}>
                        <Checkbox checked={fields.findIndex((item: string) => item === field) >= 0} />
                        <ListItemText primary={field} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name='fromDate'
              defaultValue={null}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDateTimePicker
                  value={value}
                  label='From Date'
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} fullWidth error={invalid} />}
                  inputFormat='dd/MM/yyyy HH:mm'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name='toDate'
              defaultValue={null}
              control={control}
              rules={{
                validate: {
                  isValid: (value: DateTime) => value && value.isValid,
                },
              }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDateTimePicker
                  value={value}
                  onChange={onChange}
                  label='To Date'
                  renderInput={(props) => (
                    <TextField {...props} fullWidth error={invalid} helperText={error?.message} />
                  )}
                  inputFormat='dd/MM/yyyy HH:mm'
                />
              )}
            />
          </Grid>
          {fields.includes('whitelist') && (
            <Grid item xs={12} sm={6}>
              <Controller
                name='whitelist'
                defaultValue='both'
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} select fullWidth label='Whitelist'>
                    <MenuItem value='true'>True</MenuItem>
                    <MenuItem value='false'>False</MenuItem>
                    <MenuItem value='both'>Both</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          )}
          {fields.includes('email_confirmed') && (
            <Grid item xs={12} sm={6}>
              <Controller
                name='email_confirmed'
                defaultValue='both'
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} select fullWidth label='Email Confirmed'>
                    <MenuItem value='true'>True</MenuItem>
                    <MenuItem value='false'>False</MenuItem>
                    <MenuItem value='both'>Both</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleExport}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ExportPopup;
