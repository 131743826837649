import { Home } from 'views/Home';
import { ItemList } from 'views/Item';
import { ItemMint } from 'views/ItemMint';
import { UserList } from 'views/User';
import { ServerStatistic } from 'views/ServerStatistic';
import { EastfogAccountList } from 'views/EastfogAccount';
import { EastfogItemList } from 'views/EastfogItem';
import { AirdropEventList, AirdropEventDetail } from 'views/Airdrop';
import { SyncPage } from 'views/SyncPage';
import { PaymentTokenList } from 'views/PaymentToken';
import SaleList from 'views/Sale/SaleList';
import { CalendarEvents } from 'views/CalendarEvents';
import { CalendarCategoryList } from 'views/CalendarCategory';
import { Fragment } from 'react';
import { GameDashboard } from 'views/GameDashboard';
import { SystemConfig } from 'views/SystemConfig';
import { RefList } from 'views/Ref';
import { SubmissionList } from 'views/Submission';

type RouteType = {
  path: string;
  url?: (query: any) => string;
  name?: string;
  element: JSX.Element;
};

type PrivateRouteType = {
  [key: string]: RouteType;
};

const privateRoute: PrivateRouteType = {
  home: {
    path: '/dashboard',
    name: 'Dashboard',
    element: <Home />,
  },
  management: {
    path: '/management',
    name: 'Management',
    element: <Fragment />,
  },
  user: {
    path: '/management/users',
    name: 'User Management',
    element: <UserList />,
  },
  item: {
    path: '/management/items',
    name: 'Item Management',
    element: <ItemList />,
  },
  sale: {
    path: '/management/sales',
    name: 'Sale Management',
    element: <SaleList />,
  },
  submission: {
    path: '/management/submissions',
    name: 'Vote Management',
    element: <SubmissionList />,
  },
  ref: {
    path: '/referral',
    name: 'Export Referral',
    element: <RefList />,
  },
  itemMint: {
    path: '/item-mint',
    name: 'Item Mint',
    element: <ItemMint />,
  },
  statistics: {
    path: '/statistics',
    name: 'Statistics',
    element: <Fragment />,
  },
  serverStatistics: {
    path: '/server-statistics',
    name: 'Server Statistics',
    element: <ServerStatistic />,
  },
  eastfogAccount: {
    path: '/eastfog-account',
    name: 'Eastfog Account',
    element: <EastfogAccountList />,
  },
  eastfogItemStatistics: {
    path: '/eastfog-item-statistics',
    name: 'Eastfog Item Statistics',
    element: <EastfogItemList />,
  },
  paymentToken: {
    path: '/payment-token-management',
    name: 'Payment Token Management',
    element: <PaymentTokenList />,
  },
  events: {
    path: '/events',
    name: 'Events',
    element: <Fragment />,
  },
  airdropEvent: {
    path: '/airdrop-events',
    name: 'Airdrop Events',
    element: <AirdropEventList />,
  },
  airdropEventDetail: {
    path: '/airdrop-events/:id',
    name: 'Airdrop Event Detail',
    url: ({ id }: { id: string }) => `/airdrop-events/${id}`,
    element: <AirdropEventDetail />,
  },
  syncPage: {
    path: '/sync-event',
    name: 'Sync Event',
    element: <SyncPage />,
  },
  calendarEvents: {
    path: '/calendar-events',
    name: 'Calendar Events',
    element: <CalendarEvents />,
  },
  calendarCategories: {
    path: '/calendar-categories',
    name: 'Calendar Categories',
    element: <CalendarCategoryList />,
  },
  gameDashboard: {
    path: '/game-dashboard',
    name: 'Game Dashboard',
    element: <GameDashboard />,
  },
  systemConfig: {
    path: '/system-config',
    name: 'System Config',
    element: <SystemConfig />,
  },
};

export default privateRoute;
