import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { TableRowEmpty } from 'components';

const TableNft = ({ dataSync }: { dataSync: EventExternalTransferredItemType[] }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Token ID</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Block</TableCell>
            <TableCell>Transaction Hash</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSync.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.tokenId}</TableCell>
              <TableCell>{item.from}</TableCell>
              <TableCell>{item.to}</TableCell>
              <TableCell>{item.block}</TableCell>
              <TableCell>{item.transactionHash}</TableCell>
            </TableRow>
          ))}
          <TableRowEmpty visible={dataSync.length === 0} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableNft;
