import {
  Button,
  CardMedia,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { submissionService } from 'services';
import { DeleteConfirmPopup, SubmissionPopup } from './popups';

const SubmissionList = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching } = useQuery(
    ['submissionService.fetchSubmissions', dataSearch],
    () => submissionService.fetchSubmissions(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages: totalPage } = data ?? {};

  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [itemChoice, setItemChoice] = useState<SubmissionType>();

  return (
    <>
      <div className='flex items-center justify-end'>
        <Button
          variant='contained'
          onClick={() => {
            setItemChoice(undefined);
            setOpenCreatePopup(true);
          }}
        >
          Create Submission
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>NFT Contract</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <CardMedia image={item.imageUrl} className='w-32 h-40 border'></CardMedia>
                  </TableCell>
                  <TableCell align='center'>{item.name}</TableCell>
                  <TableCell align='center'>{item.nftContract}</TableCell>
                  <TableCell align='center'>{item.creator}</TableCell>
                  <TableCell align='center'>
                    {item.createdAt && DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm')}
                  </TableCell>
                  <TableCell align='center'>
                    {item.updatedAt && DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    <div className='flex items-center space-x-2 justify-center'>
                      <Button
                        variant='contained'
                        size='medium'
                        color='warning'
                        onClick={() => {
                          setOpenCreatePopup(true);
                          setItemChoice(item);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        variant='contained'
                        color='error'
                        size='medium'
                        onClick={() => {
                          setOpenDeletePopup(true);
                          setItemChoice(item);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{total ?? 0} Submissions</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openDeletePopup} fullWidth maxWidth='xs'>
        <DeleteConfirmPopup id={itemChoice?.id!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>

      <Dialog open={openCreatePopup} fullWidth maxWidth='md'>
        <SubmissionPopup item={itemChoice} onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
    </>
  );
};

export default SubmissionList;
