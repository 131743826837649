import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { dashboardService } from 'services';

const EastfogItemList = () => {
  const [dataSearch, onSearchChange] = useSearch({ search: '' });

  const { data, isFetching } = useQuery(
    ['dashboardService.fetchEastFogItemStatistic', dataSearch],
    () => dashboardService.fetchEastFogItemStatistic(dataSearch),
    { keepPreviousData: true },
  );

  const { items = [], currentPage, total, pages: totalPage } = data ?? {};

  return (
    <>
      <CommonSearch onChange={onSearchChange} />

      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='whitespace-nowrap'>{item.name}</TableCell>
                  <TableCell align='center'>{item.count}</TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{total ?? 0} Items</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </>
  );
};

export default EastfogItemList;
