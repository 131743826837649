import { client } from './axios';

const fetchSubmissions = (params?: PaginateParamsType): Promise<SubmissionPaginateType> =>
  client.get(`/dashboard-apis/api/submission`, { params });

const createSubmission = (body: SubmissionCreateType): Promise<SubmissionType> =>
  client.post(`/dashboard-apis/api/submission`, body);

const updateSubmission = ({ id, ...body }: SubmissionCreateType): Promise<SubmissionType> =>
  client.patch(`/dashboard-apis/api/submission/${id}`, body);

const deleteSubmission = ({ id }: { id: string }) => client.delete(`/dashboard-apis/api/submission/${id}`);

export default {
  fetchSubmissions,
  createSubmission,
  updateSubmission,
  deleteSubmission,
};
