import { ContentCopyOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
  Pagination,
} from '@mui/material';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { dashboardService } from 'services';
import { shorten } from 'utils/common';

type DetailPopupProps = PopupController & {
  userId: string;
};

const DetailPopup = ({ userId, onClose }: DetailPopupProps) => {
  const [dataSearch, onSearchChange] = useSearch({ search: '' });

  const { enqueueSnackbar } = useSnackbar();

  const { data, isFetching } = useQuery(
    ['dashboardService.getRefsUser', { userId, ...dataSearch }],
    () => dashboardService.getRefsUser({ userId, ...dataSearch }),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages: totalPage } = data ?? {};

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <>
      <DialogTitle>DETAIL REFS</DialogTitle>

      <DialogContent>
        <CommonSearch onChange={onSearchChange} />

        <TableContainer component={Paper}>
          <Spinner loading={isFetching}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Refcode</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.refCode}>
                    <TableCell className='text-center whitespace-nowrap'>{item.username}</TableCell>
                    <TableCell>
                      <div className='flex items-center justify-center space-x-2'>
                        <div>{shorten(item.address)}</div>
                        {item.address && (
                          <IconButton onClick={() => copy(item.address)} title='Copy'>
                            <ContentCopyOutlined />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align='center'>{item.email}</TableCell>
                    <TableCell align='center'>{item.refCode}</TableCell>
                  </TableRow>
                ))}
                <TableRowEmpty visible={!isFetching && items.length === 0} />
              </TableBody>
              <caption className='font-bold border-t'>{total ?? 0} Users</caption>
            </Table>
          </Spinner>
        </TableContainer>
        <div className='flex justify-center'>
          <Pagination
            page={currentPage ?? 1}
            count={totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DetailPopup;
